@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
  p {
    @apply mb-6;
  }
  // .page-content {
  //   @apply overflow-x-hidden;
  // }
}

@layer components {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // @apply text-[#202124];
    @apply text-arrivalhub-raisin-black;
  }

  .entry-content h3 {
    @apply mb-5 text-lg font-semibold;
  }

  .entry-content ul {
    @apply mb-6 px-4 lg:mb-[50px];
  }

  .entry-content ul li {
    @apply mb-2 list-disc leading-9;
  }

  .entry-content p {
    @apply mb-6 lg:mb-[50px];
  }
  ._2p3a {
    width: 100% !important;
  }
  // bbc-accordion
  @mixin spacing-base($mb) {
    ol {
      &:last-child {
        margin-bottom: $mb;
      }
      @apply mb-4 pl-5;

      li {
        @apply list-decimal;
        ul {
          li {
            @apply list-decimal;
          }
        }
      }
    }
    ul {
      &:last-child {
        margin-bottom: $mb;
      }
      @apply mb-4 pl-5;

      li {
        &::marker {
          font-size: 12px;
        }
        @apply list-disc;

        ul {
          li {
            &::marker {
              font-size: 12px;
            }
            @apply list-circle;
          }
        }
      }
    }
    a {
      @apply text-blue-500 hover:text-blue-700;
    }
  }
  // bbc-accordion
}
